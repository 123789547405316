import { MetricPanelProps } from "../../services/types";
import { getCountyIds, getMetricGroupChartId, getMetricGroupOrderIds, getSvgMap } from "../../utils/dashboardUtils";
import EmploymentByIndustryChart from "../charts/EmploymentByIndustryChart";
import IndustryBLSTRSCharts from "../charts/IndustryBLSTRSCharts";
import DisaggregateMetricCard from "../DisaggregateMetricCard";

const EconomyMetrics = ({ indicators, categorizedData, tab, blsTrsData }: MetricPanelProps) => {   
    const econIds = getMetricGroupOrderIds(tab);
    console.log("economy counties: ", getCountyIds());

    const industryData = indicators.find(data => data.id === getMetricGroupChartId("Economy", "empind"));

    return (
        <div className="flex flex-wrap">
            {econIds.length > 0 && 
            <div className="flex w-full">
                {categorizedData.Economy.filter(metric => 
                    econIds.includes(metric.id) // Metric ID for Employment by Industry
                ).map(metric => (
                    <DisaggregateMetricCard
                        key={metric.id}
                        id={metric.id}
                        title={metric.name}
                        subText="Total Jobs:"
                        value={metric.versions[0].value}
                        unit={metric.versions[0].unit}
                        svg={getSvgMap(metric.id)}
                    />
                ))}
            </div>
            }
            {industryData && <EmploymentByIndustryChart disaggregatedData={indicators}/>}
            {blsTrsData.blsFirmsData.length > 0 && blsTrsData.blsJobsData.length > 0 && blsTrsData.trsData.length > 0 && 
            <div className="flex w-full mt-[2rem]">
                <IndustryBLSTRSCharts data={blsTrsData}/>
            </div>
            }
        </div>
    );
}

export default EconomyMetrics;