import { memo, useRef } from "react";
import { Chart } from "chart.js/auto";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";

interface SparkLineChartProps {
    data: { label: string, value: number }[];
    height?: string;
    width?: string;
}

const SparkLineChart: React.FC<SparkLineChartProps> = memo(({ data, height, width }) => {

    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"line"> | null>(null);

    useDeepCompareEffect(() => {

        console.log("RENDER SPARKLINE CALLED!");

        const ctx = chartRef.current?.getContext("2d");
        if (ctx) {
            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            // Extract labels and data
            const labels = data.map(d => d.label);
            const chartData = data.map(d => d.value);

            chartInstance.current = new Chart(ctx, {
                type: "line",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            data: chartData,
                            borderColor: "#3A3C41",
                            borderWidth: 2,
                            fill: false,
                            cubicInterpolationMode: 'monotone',
                            tension: 0.4,
                            label: "",
                            pointStyle: "circle",
                            pointBackgroundColor: "#DB672D",
                            pointBorderColor: "#DB672D",
                            pointHoverRadius: 5
                        },
                    ],
                },
                options: {
                    animation: false,
                    elements: {
                        point: {
                          radius: (ctx) => (ctx.chart.data.datasets[ctx.datasetIndex].data.length - 1 === ctx.dataIndex ? 3 : 0)
                        }
                    },
                    maintainAspectRatio: false,
                    responsive: true,
                    plugins: {
                        datalabels: {
                            display: false
                        },
                        title: {
                            display: false,
                            text: 'Sparkline'
                        },
                        legend: {
                            display: false
                        }
                    },
                    interaction: {
                      intersect: false,
                    },
                    scales: {
                      x: {
                        display: false,
                        title: {
                          display: false
                        }
                      },
                      y: {
                        display: false,
                        title: {
                          display: false
                        },
                        suggestedMin: Math.min(...chartData),
                        suggestedMax: Math.max(...chartData)
                      }
                    }
                },
            });
        }
    }, [data]);

    return (
        <div className="w-full max-w-md">
            <div className={`${height ? height : "h-20" } ${ width ? width : "w-60" } `}> {/* Adjust height as needed */}
                <canvas ref={chartRef} className="w-full"/>
            </div>
        </div>
    );
})

export default SparkLineChart;