import MetricCard from "../components/MetricCard";
import DisaggregateMetricCard from "../components/DisaggregateMetricCard";
import OwnershipCard from "../components/OwnershipCard";
import IndustryCategoryChart from "../components/charts/IndustryCategoryChart";
import EmploymentByIndustryChart from "../components/charts/EmploymentByIndustryChart";
import { GeoDetails } from "../services/types";
import { formatValue } from "../utils/helpers";
import { categorizeData, getSvgMap, getMetricGroupOrderIds, getMetricGroupChartId } from "../utils/dashboardUtils";
import interactionsSvg from '../assets/interactions-tab.svg';
import jobsSvg from '../assets/jobs.svg';
import adoSvg from '../assets/logo-ado.svg';
import printWhiteSvg from '../assets/print-white.svg';
import RaceEthnicityChart from "../components/charts/RaceEthnicityChart";
import ImmigrationChart from "../components/charts/ImmigrationChart";
import HouseholdIncomeChart from "../components/charts/HouseholdIncomeChart";
import { Key } from "react";
import useFetchAllIndicators from "../hooks/useFetchAllIndicators";

interface MetricsModalProps {
    isOpen: boolean;
    onClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
    geoDetails: GeoDetails[];
    pdfUrl: string;
    logoUrl: string;
    legendUrl: string;
    selectedArea: string;
    siteUrl: string;
}

const MetricsModal: React.FC<MetricsModalProps> = ({ isOpen, onClose, geoDetails, pdfUrl, logoUrl, legendUrl, selectedArea, siteUrl }) => {

    const { disaggregatedIndicators: disaggregatedData, interactionData } = useFetchAllIndicators(isOpen, geoDetails);

    const categorizedData = categorizeData(disaggregatedData);
    console.log('legend url:', legendUrl);
    console.log('disagg data:', disaggregatedData);

    const industryCategoryData = interactionData.find(data => data.attribute_tallies.some(tally => tally.name === "Industry Category"));

    //// Sort metrics for manual ordering
    //
    // Demographics
    const demoOrder = getMetricGroupOrderIds("Demographics"); // Metric IDs for Population, Households under 200% Poverty, Limited English Proficiency

    const sortedDemoMetrics = categorizedData.Demographics
        .filter(metric => demoOrder.includes(metric.id)) // Filter the metrics
        .sort((a, b) => demoOrder.indexOf(a.id) - demoOrder.indexOf(b.id)); // Sort the metrics based on the defined order

    // Workforce
    const workforceOrders = getMetricGroupOrderIds("Workforce");

    const sortedWorkforceMetrics = workforceOrders.map((order: number[]) => 
        categorizedData.Workforce
            .filter(metric => order.includes(metric.id))
            .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
    );

    const handlePrint = () => {
        const originalTitle = document.title;
        document.title = `${selectedArea} Dashboard Metrics`;
        window.print();
        document.title = originalTitle;
    };

    return (
        <div id="print-container" className="fixed inset-0 bg-white p-4 overflow-y-auto z-50">
            {/* Wrapper to limit the modal's width */}
            <div className="max-w-[800px] mx-auto w-full">
                <div className="relative h-full w-full p-6">
                    <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-800">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                    {/* Print and Close buttons */}
                    <div className="flex justify-center no-print">
                        <button 
                            onClick={handlePrint}
                            className="flex mx-2 bg-color-cai-blue text-white py-2 px-4 rounded-md hover:bg-blue-500"
                        >
                            <img src={printWhiteSvg} alt="Print" className="w-5 h-5 mr-2" />
                            <p className="text-sm">Print</p>
                        </button>
                        <button 
                            onClick={onClose}
                            className="bg-transparent border border-color-cai-lightGray text-color-cai-dashboard py-2 px-4 rounded-md hover:bg-color-cai-lightGray"
                        >
                            <p className="text-sm">Close</p>
                        </button>
                    </div>

                    {/* Page 1 */}
                    <div className="my-8 flex flex-col items-center justify-center relative">
                        <div className="w-full p-5 flex justify-start items-center bg-gotham-gray rounded-md">
                            <img src={adoSvg} className="h-70 w-70"></img>
                            <div className="flex flex-col ml-4">
                                <p className="text-white text-xl font-bold">Commerce ADO Reporting Map</p>
                                <p className="text-white text-sm mt-2">{`SELECTED AREA: ${selectedArea}`}</p>
                                <p className="text-white text-sm font-semibold">{siteUrl}</p>
                            </div>
                        </div>
                        <div className="relative inline-block my-8">
                            <img src={pdfUrl} className="h-auto w-auto" alt="Esri Map Image" />
                            {/* { <div className="flex justify-between legend-image">
                                { legendUrl && <img src={legendUrl} className="h-[188px] w-[220px] ml-6 mt-[-5rem]" alt="Esri Legend Image" /> }
                                <img src={logoUrl} className="h-10 w-30 mr-8 mt-[-1rem]" alt="Esri Logo Image" />
                            </div> } */}
                            <div className="flex justify-end">
                                <img src={logoUrl} className="h-10 w-30 mr-10 -mt-36" alt="Esri Logo Image" />
                            </div>
                        </div>
                    </div>

                    {/* Page 2 */}
                    <div className="page">
                        <div className="border bg-gotham-gray my-4 flex flex-col items-center justify-center rounded-md">
                            <h2 className="font-bold text-white text-lg text-center">Interactions</h2>
                        </div>
                        <div className="w-full flex flex-wrap">
                            <div className="flex w-[45%] justify-center items-end">
                                {interactionData.length > 0 && typeof(interactionData) != "string" && (
                                    <div className="flex flex-col">
                                        <div className="flex w-full">
                                            <div className="flex flex-col items-center m-2 w-full">
                                                <img src={interactionsSvg} alt="Interactions icon" className="w-10 h-10" />
                                                <div className="flex flex-col items-center w-full">
                                                    <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(Number(interactionData[0].item_count), 'integer')}</h2>
                                                    <p className="text-color-cai-gray text-sm font-bold">Interactions</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center m-2 w-full">
                                                <img src={jobsSvg} alt="Jobs icon" className="w-10 h-10" />
                                                <div className="flex flex-col items-center w-full">
                                                    <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(Number(interactionData[0].item_total), 'integer')}</h2>
                                                    <p className="text-color-cai-gray text-sm font-bold">Jobs</p>
                                                </div>
                                            </div>
                                        </div>
                                        {interactionData[0].attribute_tallies.length ?
                                            <div>
                                                <p className="mt-8 ml-6 text-color-cai-gray text-sm font-bold">Ownership Details</p>
                                                <div className="flex w-full flex-wrap items-end justify-center">
                                                    {interactionData[0]?.attribute_tallies.find(tally => tally.name === "Classification")?.tallies.map((tally, index) => (
                                                        <OwnershipCard
                                                            key={index}
                                                            title={tally.name}
                                                            value={tally.value}
                                                            total={interactionData[0]?.attribute_tallies.find(tally => tally.name === "Classification")?.total_tally || 0}
                                                            unit="percentage"
                                                        />
                                                    ))}
                                                </div>
                                            </div> : ''
                                        }
                                    </div>
                                )}
                            </div>
                            {industryCategoryData && 
                                <div className="flex flex-col w-[55%] pl-8">
                                    <IndustryCategoryChart interactionData={interactionData}/>
                                </div>
                            }
                        </div>
                    
                        <div className="border bg-gotham-gray my-4 flex flex-col items-center justify-center rounded-md">
                            <h2 className="font-bold text-white text-lg text-center">Demographics</h2>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="w-full flex justify-between">
                            {sortedDemoMetrics.map((metric) => (
                                <MetricCard
                                    key={metric.id}
                                    id={metric.id}
                                    title={metric.name}
                                    value={metric.versions[0].value}
                                    unit={metric.versions[0].unit}
                                    svg={getSvgMap(metric.id)}
                                    showMetadataIcon={false}
                                />
                            ))}
                            </div>
                            <div className="flex w-full justify-between mt-4">
                                <div className="flex flex-col w-full">
                                    {categorizedData.Demographics.filter(metric => 
                                        [getMetricGroupChartId("Demographics", "race")].includes(metric.id) // Race & Ethnicity
                                    ).map(metric => (
                                        <DisaggregateMetricCard
                                            key={metric.id}
                                            id={metric.id}
                                            title={metric.name}
                                            subText=""
                                            svg={getSvgMap(metric.id)}
                                            showMetadataIcon={false}
                                        />
                                    ))}
                                    <div className="ml-4">
                                        <RaceEthnicityChart disaggregatedData={disaggregatedData}/>
                                    </div>
                                </div>
                                <div className="flex flex-col w-full ml-8">
                                    {categorizedData.Demographics.filter(metric => 
                                        [getMetricGroupChartId("Demographics", "immigration")].includes(metric.id) // Top Countries of Immigration
                                    ).map(metric => (
                                        <DisaggregateMetricCard
                                            key={metric.id}
                                            id={metric.id}
                                            title={metric.name}
                                            subText=""
                                            svg={getSvgMap(metric.id)}
                                            showMetadataIcon={false}
                                        />
                                    ))}
                                    <ImmigrationChart disaggregatedData={disaggregatedData}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Page 3 */}
                    <div className="page">
                        <div className="border bg-gotham-gray my-4 flex flex-col items-center justify-center rounded-md">
                            <h2 className="font-bold text-white text-lg text-center">Workforce</h2>
                        </div>
                        <div className="flex flex-wrap">
                            <div className="flex w-1/2 justify-between items-center">
                                {sortedWorkforceMetrics.map((sortedGroup: any[], index: Key | null | undefined) => (
                                    <div className="flex flex-col flex-wrap" key={index}>
                                        {sortedGroup.map(metric => (
                                            <MetricCard
                                                key={metric.id}
                                                id={metric.id}
                                                title={metric.name}
                                                value={metric.versions[0].value}
                                                unit={metric.versions[0].unit}
                                                svg={getSvgMap(metric.id)}
                                                showMetadataIcon={false}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-center items-center w-1/2">
                                <div className="flex flex-col items-start ml-28">
                                    {categorizedData.Workforce.filter(metric => 
                                        [getMetricGroupChartId("Workforce", "mhhinc")].includes(metric.id) // Median HHIncome
                                    ).map(metric => (
                                        <DisaggregateMetricCard
                                            key={metric.id}
                                            id={metric.id}
                                            title={metric.name}
                                            subText=""
                                            svg={getSvgMap(metric.id)}
                                            showMetadataIcon={false}
                                        />
                                    ))}
                                    <div className="flex flex-col">
                                    <HouseholdIncomeChart disaggregatedData={disaggregatedData}/>
                                    <p className="text-xs text-color-cai-gray bottom-0 left-[3.3rem] relative">Number of Households</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="border bg-gotham-gray mt-8 mb-4 flex flex-col items-center justify-center rounded-md">
                            <h2 className="font-bold text-white text-lg text-center">Economy</h2>
                        </div>
                        <div className="flex justify-center items-center w-full">
                            <div className="flex flex-col items-start">
                                {categorizedData.Economy.map((metric) => (
                                    <DisaggregateMetricCard
                                        key={metric.id}
                                        id={metric.id}
                                        title={metric.name}
                                        subText="Total Jobs:"
                                        value={metric.versions[0].value}
                                        unit={metric.versions[0].unit}
                                        svg={getSvgMap(metric.id)}
                                        showMetadataIcon={false}
                                    />
                                ))}
                                <EmploymentByIndustryChart disaggregatedData={disaggregatedData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MetricsModal;