import { memo, useRef, useState } from "react";
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import { DisaggregatedMetric } from "../../services/types";
import { getMetricGroupChartId } from "../../utils/dashboardUtils";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from "chart.js";
import { formatValue } from "../../utils/helpers";

const desiredOrder = [
    "American Indian and Alaska Native",
    "Asian",
    "Black or African American",
    "Native Hawaiian and Other Pacific Islander",
    "Some Other Race",
    "Two or More Races",
    "White",
];

const desiredLatinoOrder = [
    "Hispanic or Latino",
    "Not Hispanic or Latino"
];

interface RaceEthnicityChartProps {
    disaggregatedData: DisaggregatedMetric[];
}

const RaceEthnicityChart = memo(({ disaggregatedData }: RaceEthnicityChartProps) => {
    const [data, setData] = useState<{ label: string, value: number, rawValue: number, isPlaceholder: boolean }[]>([]);
    const [barColors, setBarColors] = useState<string[]>([]);
    const title = "Race & Ethnicity";
    const height = "h-60";
    const backgroundColor = '#4D4D4D'

    useDeepCompareEffect(() => {
        if (!disaggregatedData) return;

        const raceData = disaggregatedData.find(data => data.id === getMetricGroupChartId("Demographics", "race"));
        const latinoData = disaggregatedData.find(data => data.id === getMetricGroupChartId("Demographics", "racelat"));

        if (!raceData) return;
        if (!latinoData) return;

        const raceVersion = raceData.versions[0];
        const latinoVersion = latinoData.versions[0];

        if (!raceVersion && !latinoVersion) return;

        const tallyMap = new Map<string, number>();
        const rawValueMap = new Map<string, number>();

        raceVersion.disaggregations.forEach(d => {
            const percentage = (d.value / raceVersion.value) * 100;
            tallyMap.set(d.name, percentage);
            rawValueMap.set(d.name, d.value); // Store the raw value
        });

        latinoVersion.disaggregations.forEach(d => {
            const percentage = (d.value / latinoVersion.value) * 100;
            tallyMap.set(d.name, percentage);
            rawValueMap.set(d.name, d.value); // Store the raw value
        });

        const orderedData = [
            ...desiredOrder.map(label => ({
                label,
                value: tallyMap.get(label) ?? 0,
                rawValue: rawValueMap.get(label) ?? 0,
                isPlaceholder: false
            })),
            { label: " ", value: 0, rawValue: 0, isPlaceholder: true }, // Adding a separator with a placeholder property
            ...desiredLatinoOrder.map(label => ({
                label,
                value: tallyMap.get(label) ?? 0,
                rawValue: rawValueMap.get(label) ?? 0,
                isPlaceholder: false
            }))
        ];

        // set barColors
        const barColors = orderedData.map((_, index) => {
            if (index === desiredOrder.length) {
                return '#FFFFFF'; // Set color for the empty bar (space)
            }
            return index >= orderedData.length - 2 ? '#D3D3D3' : '#4D4D4D'; // Default and other column colors
        });

        setData(orderedData);
        setBarColors(barColors);
        // setFormatter(formatter);
    }, [disaggregatedData]);


    // Register the plugin to all charts:
    Chart.register(ChartDataLabels);
    const chartRef = useRef<HTMLCanvasElement>(null);
    const chartInstance = useRef<Chart<"bar"> | null>(null);

    useDeepCompareEffect(() => {
        const ctx = chartRef.current?.getContext("2d");
        if (ctx) {
            // Destroy existing chart instance if it exists
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }

            // Extract labels and data
            const labels = data.map(d => d.label);
            const chartData = data.map(d => d.value);

            // Set max and min values for label padding
            const maxValue = Math.max(...chartData) * 1.5;
            const minValue = -maxValue * .25;

            // Use provided barColors or fallback to backgroundColor for all bars
            const colors = barColors && barColors.length === data.length
                ? barColors
                : new Array(data.length).fill(backgroundColor);

            chartInstance.current = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: chartData,
                            backgroundColor: colors,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,  // Allows the chart to take the full height
                    indexAxis: 'y',
                    plugins: {
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            anchor: 'start',
                            align: 'start',
                            formatter: ((value, context) => {
                                const isPlaceholder = data[context.dataIndex].isPlaceholder;
                                if (isPlaceholder) {
                                    return ''; // Hide the label for the placeholder
                                }
                                return value > 0 ? `${value.toFixed(0)}%` : ''; // Show label for other zero values
                            }),
                            font: {
                                family: 'Gotham',
                                size: 12,
                                weight: 'normal'
                            },
                            offset: 0
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const rawValue = data[context.dataIndex].rawValue; // Get the raw value from the data array
                                    return `${formatValue(rawValue, 'integer')}`; // Display raw value in tooltip
                                }
                            },
                            backgroundColor: "#FFFFFF",
                            titleColor: "#4D4D4D", 
                            bodyColor: "#4D4D4D",
                            borderColor: "#4D4D4D",
                            borderWidth: 1,
                            titleFont: {
                                family: 'Gotham'
                            },
                            bodyFont: {
                                family: 'Gotham'
                            }
                        },
                    },
                    scales: {
                        x: {
                            beginAtZero: true,
                            min: minValue,
                            max: maxValue,
                            ticks: {
                                display: false,
                            },
                            grid: {
                                display: false,
                                drawTicks: false
                            },
                            border: {
                                display: false,
                                dash: [2, 4]
                            }
                        },
                        y: {
                            grid: {
                                display: false,
                                drawTicks: true,
                            },
                            border: {
                                display: false
                            },
                            ticks: {
                                color: '#4D4D4D',
                                minRotation: 0,  // Ensure labels are parallel to y-axis
                                maxRotation: 0,  // Ensure labels are parallel to y-axis
                                font: {
                                    family: 'Gotham', 
                                    size: 12,
                                    weight: 'normal'
                                },
                                padding: 0,
                                autoSkip: false,  // Ensure all labels are displayed
                                callback: function (value: any, index: number) { 
                                // Callback to wrap long text labels
                                    const maxLabelWidth = 125;
                                    const labels = this.chart.data.labels;
                                    if (!labels) {
                                        console.log(value);
                                        return;
                                    }
                                    const label = labels[index] as string;

                                    // Wrap long labels into multiple lines by words
                                    const lines = [];
                                    let currentLine = '';
                                    const words = label.split(' ');

                                    for (let word of words) {
                                        const testLine = currentLine + (currentLine ? ' ' : '') + word;
                                        // Measure the width of the test line
                                        const testWidth = this.chart.ctx.measureText(testLine).width;

                                        if (testWidth > maxLabelWidth) {
                                            if (currentLine) {
                                                lines.push(currentLine);
                                            }
                                            currentLine = word;
                                        } else {
                                            currentLine = testLine;
                                        }
                                    }
                                    lines.push(currentLine);

                                    return lines;
                                }
                            },
                        },
                    },
                },
            });
        }
    }, [data, title, backgroundColor]);

    return (
        <div className="w-full max-w-md">
            <div className={height ? height : `h-80`}> {/* Adjust height as needed */}
                <canvas ref={chartRef} className="w-full"/>
            </div>
        </div>
    );
})

export default RaceEthnicityChart;