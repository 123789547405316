import { useEffect, useState } from "react";
import MetricMetadataModal from "./MetricMetadataModal";
import { MetricMetadata } from "../services/types";
import { fetchMetricMetadata } from "../utils/dashboardUtils";

interface LegendMetricModalProps {
    id: number;
    isOpen: boolean;
    onClose: any;
    svg: string;
};

const LegendMetricModal = ({ id, isOpen, onClose, svg }: LegendMetricModalProps) => {
    const [showMetadata, setShowMetadata] = useState(isOpen);
    const [metadata, setMetadata] = useState<MetricMetadata[] | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        setShowMetadata(isOpen);
        if (isOpen && id) {
            fetchMetadata();
        }
    }, [isOpen, id]); // Fetch metadata when modal opens or ID changes

    const fetchMetadata = async () => {
        setLoading(true);
        try {
            const result = await fetchMetricMetadata(id);
            setMetadata(result ? result.data : null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setShowMetadata(false);
        onClose();
    };

    return (
        <MetricMetadataModal
            isOpen={showMetadata}
            onClose={handleClose}
            metadata={metadata}
            svg={svg}
            loading={loading}
            error={error} // Pass error state if needed
        />
    );
};


export default LegendMetricModal;
