import { formatValue } from "../utils/helpers";
import metricMetadataIcon from '../assets/metric-metadata-icon.svg';
import { useState } from "react";
import MetricMetadataModal from "./MetricMetadataModal";
import { MetricMetadata } from "../services/types";
import { fetchMetricMetadata } from "../utils/dashboardUtils";

interface MetricCardProps {
    id: number;
    title: string;
    value: number;
    unit: string;
    svg: string;
    showMetadataIcon?: boolean;
};

const MetricCard = ({ id, title, value, unit, svg, showMetadataIcon = true }: MetricCardProps) => {
    const [showMetadata, setShowMetadata] = useState(false);
    const [metadata, setMetadata] = useState<MetricMetadata[] | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const handleClick = async () => {
        setShowMetadata(prev => !prev);

        if (!metadata) { // Only fetch if metadata is not already loaded
            setLoading(true);
            try {
                const result = await fetchMetricMetadata(id);
                setMetadata(result ? result.data : null);
            } catch (error) {
                setError(error as Error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <div className="ml-2 mr-4 my-2">
            <div className="flex flex-col items-start w-full h-28">
                <div className="flex items-end w-24 justify-between">
                    <img src={svg} alt={`${title} icon`} className="w-10 h-10 text-blue-500" />
                    {showMetadataIcon && 
                    <button onClick={handleClick}>
                        <img src={metricMetadataIcon} alt="Metric metadata icon" />
                    </button>
                    }
                </div>
                <p className="text-gotham-gray text-sm font-bold w-[7.5rem]">{title}</p>
                <div className="flex items-baseline w-full justify-start">
                    <h2 className="text-gotham-gray text-2xl font-bold">{formatValue(value, unit)}</h2>
                </div>
            </div>
            <MetricMetadataModal
                isOpen={showMetadata}
                onClose={handleClick}
                metadata={metadata}
                svg={svg}
                loading={loading}
                error={error}
            />
        </div>
    );
};


export default MetricCard;
