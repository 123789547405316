export const generatePageNumbers = (totalPages: number, currentPage: number): (number | string)[] => {
    let pageNumbers: (number | string)[] = [];

    // Case 1: Less than 5 pages
    if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
    } else {
        // Case 2: More than 5 pages
        let startPage = Math.max(1, currentPage - 1);
        let endPage = Math.min(startPage + 2, totalPages);

        // Add first page and ellipsis if needed
        pageNumbers.push(1);
        if (startPage > 2) {
            pageNumbers.push('...');
        }

        // Add intermediate pages
        for (let i = startPage + 1; i <= endPage; i++) {
            pageNumbers.push(i);
        }

        // Add last page and ellipsis if needed
        if (endPage < totalPages - 1) {
            pageNumbers.push('...');
        }
        
        // Ensure the last page is always included
        if (endPage !== totalPages) {
            pageNumbers.push(totalPages);
        }
    }

    return pageNumbers;
};

// number formatting for visible numbers
export function addCommas(val: number | string) {
    if (val != undefined) {
        val = val.toString().replace(/,/g, ''); //remove existing commas first
        var valSplit = val.split('.'); //then separate decimals

        while (/(\d+)(\d{3})/.test(valSplit[0].toString())) {
            valSplit[0] = valSplit[0].toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
        }

        if (valSplit.length == 2) { //if there were decimals
            val = valSplit[0] + "." + valSplit[1]; //add decimals back
        } else {
            val = valSplit[0];
        }
    }

    return val;
}

// calculateAvailableYears - get current year and historial years for ADO data
export const calculateAvailableYears = (): string[] => {
    const currentYear = new Date().getFullYear();
    const futureYear = currentYear + 1;
    const yearsArray = Array.from({ length: futureYear - 2022 + 1 }, (_, index) => (2022 + index).toString());
    return yearsArray;
};

export const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let day = today.getDate().toString().padStart(2, '0');

    // Format the date as YYYYMMDD
    const formattedDate = `${year}${month}${day}`;

    return formattedDate;
}

export function calculateFiscalYear(selectedFY: number | string) {
    // If there's a selected fiscal year in the session state, return it
    if (selectedFY) {
        return selectedFY;
    }

    // Get the current month and year
    const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based, so add 1
    const currentYear = new Date().getFullYear();

    // Determine the fiscal year
    let fiscalYear;
    if (currentMonth >= 9) {
        fiscalYear = currentYear + 1;
    } else {
        fiscalYear = currentYear;
    }

    return fiscalYear;
}

export function findFiscalYear(dateString: string) {
    // Parse the date string into a Date object
    const dateParts = dateString.split('/');
    const month = parseInt(dateParts[0], 10);
    // const day = parseInt(dateParts[1], 10);
    const year = parseInt(dateParts[2], 10);

    

    // Determine the fiscal year based on the month
    let fiscalYear;
    if (month >= 9) {
        fiscalYear = year + 1;
    } else {
        fiscalYear = year;
    }
    return fiscalYear;
}

export const formatValue = (value: number, unit: string): string => {
    // Ensure value is a valid number
    if (typeof value !== 'number' || isNaN(value)) {
        return ''; // or provide a fallback like "N/A" or "0"
    }

    switch (unit) {
        case "integer":
            return addCommas(value.toFixed(0));
        case "percentage":
            return `${(value * 100).toFixed(0)}%`;
        case "currency":
            return `$${addCommas(value.toFixed(0))}`;
        default:
            return value.toString();
    }
};