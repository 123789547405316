import { MetricPanelProps } from "../../services/types";
import interactionsSvg from '../../assets/interactions-tab.svg';
import jobsSvg from '../../assets/jobs.svg';
import { formatValue } from "../../utils/helpers";
import OwnershipCard from "../OwnershipCard";
import IndustryCategoryChart from "../charts/IndustryCategoryChart";

const InteractionMetrics = ({ interactionData }: MetricPanelProps) => {   
    const classificationTally = interactionData[0]?.attribute_tallies.find(tally => tally.name === "Classification");
    const industryCategoryData = interactionData.find(data => data.attribute_tallies.some(tally => tally.name === "Industry Category"));

        return (
            <div className="flex flex-col w-full">
                <h2 className="text-color-cai-dashboard inline-block text-[.9rem] ml-[1rem] mr-[1rem]">{ interactionData[0].item_missing_count == null || interactionData[0].item_missing_count == 0 ? 'Showing all interactions. Enable layer to filter interactions by selected area.' : 'Interactions filtered to selected area on the map.' }</h2>
                <div className="flex w-3/4 item-center justify-between ml-20 my-8">
                    <div className="mr-[3rem]"> 
                        <img src={interactionsSvg} alt={`Interactions icon`} className="ml-1 w-10 h-10 text-blue-500" />
                        <div className="flex flex-col items-start w-full">
                            <div className="flex items-center w-full justify-between">
                                <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(Number(interactionData[0].item_count), 'integer')}</h2>
                            </div>
                            <p className="text-color-cai-gray text-sm font-bold w-[7.5rem]">Interactions</p>
                        </div>
                        <p className={`text-[11px] -m-[4rem] mt-[2rem] inline-block ${ interactionData[0].item_missing_count == null || interactionData[0].item_missing_count == 0 ? 'hidden' : '' }`}>{formatValue(Number(interactionData[0].item_missing_count), 'integer')} interactions are outside of the selected area.</p>
                    </div>
                    <div className="">
                        <img src={jobsSvg} alt={`Jobs icon`} className="ml-1 w-10 h-10 text-blue-500" />
                        <div className="flex flex-col items-start w-full">
                            <div className="flex items-center w-full justify-between">
                                <h2 className="text-color-cai-dashboard text-2xl font-bold">{formatValue(Number(interactionData[0].item_total), 'integer')}</h2>
                            </div>
                            <p className="text-color-cai-gray text-sm font-bold w-[7.5rem]">Jobs</p>
                        </div>
                    </div>
                </div>
                {interactionData[0].attribute_tallies.length ?
                    <div>
                        <p className="mt-2 ml-3 text-color-cai-gray text-sm font-bold">Ownership Details</p>
                        <div className="flex flex-wrap shrink-0 ml-3">
                            {classificationTally?.tallies.map((tally, index) => (
                                <OwnershipCard
                                    key={index}
                                    title={tally.name}
                                    value={tally.value}
                                    total={parseInt(interactionData[0].item_count)}
                                    unit="percentage"
                                />
                            ))}
                        </div>
                        {industryCategoryData && 
                            <div className="w-full pl-3">
                                <IndustryCategoryChart interactionData={interactionData}/>
                            </div> 
                        }
                    </div> : ''
                }
            </div>
        );
}

export default InteractionMetrics;