import { memo, useState } from "react";
import businessTypeSvg from '../../assets/business-type.svg';
import useDeepCompareEffect from "../../hooks/useDeepCompareEffect";
import { InteractionData } from "../../services/types";
import BarChart from "./BarChart";

const desiredOrder = [
    "Construction",
    "Resources",
    "Manufacturing",
    "Wholesale, Utilities",
    "Retail",
    "Finance, Insurance, & Real Estate",
    "IT & Professional Services",
    "Health & Other Services",
    "Consumer Services",
    "Education",
    "Government"
];

interface IndustryByCategoryChartProps {
    interactionData: InteractionData[];
}

const IndustryCategoryChart = memo(({ interactionData }: IndustryByCategoryChartProps) => {
    const [chartData, setChartData] = useState<{ label: string, value: number, rawValue: number }[]>([]);

    useDeepCompareEffect(() => {
        if (!interactionData) return;

        const industryCategoryData = interactionData.find(data => data.attribute_tallies.some(tally => tally.name === "Industry Category"));
        if (!industryCategoryData) return;

        const industryCategoryTally = industryCategoryData.attribute_tallies.find(tally => tally.name === "Industry Category");
        if (!industryCategoryTally) return;

        const tallyMap = new Map<string, number>();
        const rawValueMap = new Map<string, number>();

        industryCategoryTally.tallies.forEach(tally => {
            const percentage = (tally.value / industryCategoryTally.total_tally) * 100;
            tallyMap.set(tally.name, percentage);
            rawValueMap.set(tally.name, tally.value); // Store the raw value
        });

        const orderedData = desiredOrder.map(label => ({
            label,
            value: tallyMap.get(label) ?? 0,
            rawValue: rawValueMap.get(label) ?? 0
        }));

        setChartData(orderedData);
    }, [interactionData]);

    return (
        <div className="flex flex-col w-full mt-8 pl-8">
            <img src={businessTypeSvg} alt="Type of Business logo" className="-ml-5 w-10 h-10 text-blue-500" />
            <h2 className="text-color-cai-gray text-sm font-bold -ml-5">Type of Business</h2>
            <BarChart data={chartData} title="Industry by Category" max={100} height="h-64" formatter={(value) => `${value.toFixed(0)}%`} />
        </div>
    );
})

export default IndustryCategoryChart;