import { MetricPanelProps } from "../../services/types";
import { getMetricGroupChartId, getMetricGroupOrderIds, getSvgMap } from "../../utils/dashboardUtils";
import MetricCard from "../MetricCard";
import DisaggregateMetricCard from "../DisaggregateMetricCard";
import HouseholdIncomeChart from "../charts/HouseholdIncomeChart";
import { Key } from "react";

const WorkforceMetrics = ({ indicators, categorizedData, tab }: MetricPanelProps) => {   
    const workforceOrders = getMetricGroupOrderIds(tab);

    const sortedWorkforceMetrics = workforceOrders.map((order: number[]) => 
        categorizedData.Workforce
            .filter(metric => order.includes(metric.id))
            .sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id))
    );

    // We need to create the text under the hhinc chart dynamically with unit_descriptor
    const hhIncomeData = indicators.find(data => data.id === getMetricGroupChartId("Workforce", "mhhinc"));
    console.log('hhincome data:', hhIncomeData);

    return (
        <div className="flex flex-wrap">
            {sortedWorkforceMetrics.map((sortedGroup: any[], index: Key | null | undefined) => (
                <div className="flex w-full" key={index}>
                    {sortedGroup.map(metric => (
                        <MetricCard
                            key={metric.id}
                            id={metric.id}
                            title={metric.name}
                            value={metric.versions[0].value}
                            unit={metric.versions[0].unit}
                            svg={getSvgMap(metric.id)}
                        />
                    ))}
                </div>
            ))}
            <div className="flex w-full mt-2">
                {categorizedData.Workforce.filter(metric => 
                    [getMetricGroupChartId(tab, "mhhinc")].includes(metric.id) // HHIncome
                ).map(metric => (
                    <DisaggregateMetricCard
                        key={metric.id}
                        id={metric.id}
                        title={metric.name}
                        subText=""
                        svg={getSvgMap(metric.id)}
                    />
                ))}
            </div>
            {hhIncomeData && 
            <div className="flex flex-col ml-[6.7rem]">
                <HouseholdIncomeChart disaggregatedData={indicators}/>
                <div className="flex justify-start ml-[8.5rem]">
                    <p className="text-xs text-color-cai-dashboard">{hhIncomeData?.versions[0].unit_descriptor}</p>
                </div>
            </div>
            }
        </div>
    );
}

export default WorkforceMetrics;