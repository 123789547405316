import { formatValue } from "../utils/helpers";

interface OwnershipCardProps {
    title: string;
    value: number;
    total: number;
    unit: string;
};

const OwnershipCard = ({ title, value, total, unit }: OwnershipCardProps) => {

    const percentage = total > 0 ? (value / total) : 0;

    return (
        <div className="ml-[.5rem] mr-[.5rem] my-2 w-[7rem]">
            <div className="flex flex-col items-center">
                <p className="text-gotham-gray text-xs font-bold w-26 text-center">{title}</p>
                <h2 className="text-gotham-gray text-[1.4rem] font-bold text-center">{formatValue(percentage, unit)}</h2>
            </div>
        </div>
    );
};


export default OwnershipCard;
